import React from 'react'
import type { GlobalProvider } from '@ladle/react'
import './styles.css'

export const Provider: GlobalProvider = ({ children, globalState, storyMeta }) => (
  <div>
    <h2>{storyMeta?.storyName}</h2>
    {children}
  </div>
)
